

.WindowScrollerWrapper {
      flex: 1 1 auto;
}

.backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(16, 16, 27, 0.69);
      z-index: 10;
}

.custom-video-player {
      position: relative;
      width: 100%;
      background-color: rgba(0, 0, 0, 1);
}

.custom-controls {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
}

button {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
}

#seek-bar,
#volume-bar {
      width: 100px;
}

#seek-bar {
      margin: 0 10px;
}

video {
      width: 100%;
      max-height: 100vh;
}

